/* eslint-disable react-hooks/exhaustive-deps */
//@ts-nocheck
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useCheckTabActivityDetector = () => {
  const { beAdminUrl } = window._env_;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const checkLogin = async () => {
    const res = await fetch(`${beAdminUrl}/bearer`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.ok) {
      navigate(pathname === "/login" ? "/" : pathname, { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkLogin();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};

export default useCheckTabActivityDetector;
