// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AICycleLogo from "assets/Images/AICyle-logo.png";
import LoginBackground from "assets/Images/login-background.png";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import UserContext from "userContext";
import LanguageContext from "languageContext";
import { useTranslation } from "react-i18next";
import englandFlag from "../../assets/Images/flags/englandFlag.png";
import vietnamFlag from "../../assets/Images/flags/vietnamFlag.png";
import useCheckLogin from "hooks/useCheckLogin";
import { useNavigate } from "react-router-dom";

const jsondata = window._env_;

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, setUser } = useContext(UserContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    i18n.changeLanguage(language?.language || "vi");
  }, [language, i18n]);

  useCheckLogin();

  const handleChangeLanguage = (language: string) => {
    setLanguage({ language });
    i18n.changeLanguage(language);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setUsername(value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setErrorMessage(t("auth.login.error.missing-info"));
      setError(true);
      return;
    }

    const response = await fetch(`${jsondata.beAdminUrl}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (
        data.roleIds.includes("431e3231-d5bd-4ece-9934-12659d748ce7") ||
        data.roleIds.includes("eaf99ee3-42bc-4541-8db0-c02e69e3f0b0") ||
        data.roleIds.includes("c563a162-6cac-4373-b4f2-ef2a618994ee")
      ) {
        if (!user) {
          setUser({
            firstName: data.profile.firstname,
            lastName: data.profile.lastname,
            primaryUname: data.primaryUname,
            ocrEnable: data.organizations[0].kvp.settings.enableOcrValuation,
          });
        }
        navigate("/", { replace: true });
      } else {
        setErrorMessage(t("auth.login.error.role"));
        setError(true);
      }
    } else {
      setErrorMessage(t("auth.login.error.user"));
      setError(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${LoginBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backdropFilter: "blur(200px)",
        height: "100vh",
      }}
    >
      <FormControl
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minWidth: "450px",
          p: 4,
          borderRadius: "8px",
          backgroundColor: "#FFF",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "10px",
            left: "15px",
            zIndex: 1,
          }}
        >
          <FormControl>
            <Select
              defaultValue={language?.language || "vi"}
              sx={{
                mr: 2,
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                ".MuiSelect-icon": { display: "none" },
              }}
              variant="standard"
              disableUnderline
              onChange={(e) => handleChangeLanguage(e.target.value as string)}
            >
              <MenuItem value="vi">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={vietnamFlag}
                    alt="Vietnamese flag"
                    width={25.6}
                    height={17.9}
                  />
                </Box>
              </MenuItem>
              <MenuItem value="en">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={englandFlag}
                    alt="England flag"
                    width={25.6}
                    height={17.9}
                  />
                </Box>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={AICycleLogo}
            sx={{
              width: "290px",
              height: "auto",
              marginBottom: "16px",
            }}
          />
          <Typography
            sx={{
              marginBottom: "16px",
              fontSize: "14px",
              color: "rgba(9, 30, 66, 0.9)",
              fontFamily: "Inter, sans-serif",
            }}
          >
            {t("auth.login.title")}
          </Typography>
        </Box>
        <TextField
          required
          name="username"
          value={username}
          variant="outlined"
          fullWidth
          onChange={handleUsernameChange}
          InputProps={{
            style: {
              height: "48px",
              borderRadius: "8px",
            },
          }}
        />
        <TextField
          required
          name="password"
          variant="outlined"
          fullWidth
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  onMouseDown={(event) => event.preventDefault()}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              height: "48px",
              borderRadius: "8px",
            },
          }}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{
            marginTop: "16px",
            backgroundColor: "#5768FF",
            borderRadius: "8px",
            minHeight: "48px",
            "&:hover": {
              backgroundColor: "#5768FF",
            },
          }}
          onClick={handleLogin}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Inter",
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "none",
            }}
          >
            {t("auth.login.button.title")}
          </Typography>
        </Button>
        {error && (
          <Typography
            sx={{
              color: "#FF0000",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "12px",
              textTransform: "none",
            }}
          >
            {errorMessage}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
}

export default LoginPage;
